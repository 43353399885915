<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>File Attachment</v-toolbar-title>

     
    </v-toolbar>
    <v-card>
      <v-container fluid>
      
        <v-row justify="center" id="cmp_list">
          <v-col sm="3">
            <v-combobox
              v-model="selected_company"
              :items="items"
              label="Company"
             @change="getofferinginfo"
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row justify="center" id="cmp_name">
          <v-col sm="3">
            <span style="color: black; font-size: 12; font-weight: normal"
              >Comapny Name :
              <span style="color: blue; font-size: 16; font-weight: bold">
                {{selected_company}}</span
              >
            </span>
          </v-col>
        </v-row>
        <template v-if="selected_company != null">
          <v-row>
            <v-col cols="12" sm="12">
              <v-card id="AddFile" style="margin: 8px; height: 100%">
                <v-card-title class="c-title"> Add File </v-card-title>
                <v-card-text>
                  
                 <v-row>
                    <v-col cols="12" sm="12">
                            <label>Attachment :</label>
                            <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size required="true"
                            clearable v-model="savedata.file"
                            ></v-file-input>
                    </v-col>
                 </v-row>
                </v-card-text>
                  <v-card-actions>
                           <v-btn color="primary darken-1" class="mx-auto" dark @click="save()">Save</v-btn>
                  </v-card-actions>
              </v-card>
            </v-col>
            <template>
                <div class="text-center">
                   <v-dialog v-model="dialog_upload" hide-overlay persistent width="300">
                     <v-card color="primary" dark>
                        <v-card-text>
                            Please wait File uploding...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                 </div>
              </template>
           
           
          </v-row>
         <!--Table-->
         <v-row>
           <v-col cols="12" sm="12">
            <v-card id="EditFile" style="margin: 8px; height: 100%">
                <v-card-title class="c-title"> Files </v-card-title>
                <v-data-table
                 :headers="headers"
                 :items="companyOfferingAttachmentList"
                 class="elevation-1"
                 :search="search"
               >
              <template v-slot:top>
                <v-toolbar flat color="white">
               
                  
                  <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Search"
                      single-line
                      hide-details>
                    </v-text-field>
                    <span style="width: 20px"></span>
                  <v-dialog v-model="dialog_edit" max-width="500px">
                   
                    <v-card>
                      <v-form v-model="valid">
                      <v-card-title>
                        <span class="headline">Edit Item</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                               <label>Attachment :</label>
                            <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size required="true"
                            clearable v-model="editedItem.file"
                            ></v-file-input>
                            </v-col>
                               
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        <v-btn color="primary darken-1"  @click="save" :disabled="!valid">Save</v-btn>
                      </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>


              <template v-slot:item.action="{ item }">
               <div>
                  <v-avatar style="" size="25" class="edit-avatar">
                    <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                 </v-avatar>
                   <v-btn text> <v-icon color="red" @click="deleteFile(item.id)"> mdi-delete</v-icon></v-btn>
                </div>
              </template>
            </v-data-table>
            </v-card>
               </v-col> 
         </v-row>
          <div class="text-center">
       <v-btn
                        class="btn-title ma-5 mt-8 center"
                        dark
                        color="indigo"
                        @click="toCriteria()"
                        >
                       Next<v-icon>mdi-arrow-right</v-icon>Add Criteria</v-btn>

    </div>
         <!--Table-->
        </template>
      
          
       
         
      
      </v-container>
     
     
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    dialog_add: false,
    dialog_upload:false,
    search: "",
    selected_company: null,
    items: "",
    nameidmap: "",
    snackbar: "",
    snackbar_msg: "",
 
    color: "",
    savedata: {
        file: null,
        company_name:'',
      },
    companyOfferingAttachmentList:[],
    headers: [
      { text: "Filename", value: "filename", sortable: false },
      { text: "Action", value: "action", sortable: false },
    ],
    
    editedItem: {
      id:'',
      file: null,
      company_name:''
    },
    editedIndex:-1,
    dialog_edit: false,
    scheduling_id: null,
  }),
  watch: {
    dialog_edit(val) {
      val || this.close();
    },
  },
  
  methods: {
    toCriteria()
    {
      this.$router.push({
        name: "tpo-create-criteria",
        params: { scheduling_id: this.$route.params.scheduling_id, cmp_name: this.$route.params.cmp_name, item:this.$route.params.item },
      });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
     editItem (item) {
        this.editedIndex = this.companyOfferingAttachmentList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog_edit = true
      },
   close() {
                this.dialog_edit = false;
                setTimeout(() => {
                    this.editedIndex = -1;
                }, 300);
            },
    get_company_list() {
      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingList")
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.items = res.data.companylist;
            //console.log("this.items");
            //console.log(this.items);
            this.nameidmap = res.data.nameidmap;
            
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        
    },
    getofferinginfo() {
      const data = {
        offering: this.nameidmap[this.items[0]],
      };
      if (this.selected_company) {
        data.offering = this.nameidmap[this.selected_company];
      }
      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingForFileAttachment", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.savedata.company_name=res.data.company_name
             this.companyOfferingAttachmentList=res.data.companyOfferingAttachmentList
             // window.alert(`USing the company List Company Name ${this.savedata.company_name}, companyOfferingAttachmentList: ${ this.companyOfferingAttachmentList}`)
          } else {
            //error
                window.console.log(error);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
       
    },
   

    getofferinginfobyid() {
      const data = {
        offering: this.scheduling_id,
      };
      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingForFileAttachment", data)
        .then((res) => {
          console.table(res.data);
          if (res.data.msg == "200") {
            this.savedata.company_name=res.data.company_name
            this.companyOfferingAttachmentList=res.data.companyOfferingAttachmentList
           // window.alert(`Company Name ${this.savedata.company_name}, companyOfferingAttachmentList: ${ this.companyOfferingAttachmentList}`)
            //this.showcriteria = true;
          } else {
           
              window.console.log(error);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        
    },
   
    save() {
       if (this.editedIndex > -1) {
                if(this.editedItem.file == null){
                       this.showSnackbar("#b71c1c", "Please Select A File...");
                }
                else
                {
                   // window.alert(`EditedItem ${JSON.stringify(this.editedItem)}`)
                             let formData = new FormData();
                              formData.append("id", this.editedItem.id);
                             formData.append("file", this.editedItem.file);
                             formData.append("scheduling_id", this.scheduling_id);
                                document.getElementById("AddFile").style.display = "none";
                                document.getElementById("EditFile").style.display = "none";
                               this.dialog_upload=true
                             //formData.append("company_name", this.editedItem.company_name);
                            axios
                                .post("/TPOCompanyScheduling/editAttachment",formData)
                                .then(res => {
                                    if (res.data.msg == "200") {
                                       //window.console.log(res.data.msg)
                                       // Object.assign(this.companyOfferingAttachmentList[this.editedIndex], this.editedItem);
                                       
                                        this.dialog_upload=false
                                        this.showSnackbar(
                                            "#4caf50",
                                            "File Updated Successfully..."
                                        ); // show snackbar on success
                                        document.getElementById("AddFile").style.display = "block";
                                        document.getElementById("EditFile").style.display = "block";
                                         this.onLoad()
                                    }
                                    else if (res.data.msg == "2001") {
                                    //window.alert(res.data.status.code)
                                    this.dialog_upload=false
                                    this.showSnackbar("#b71c1c", "File Already Present!!!"); // show snackbar on error
                                    document.getElementById("AddFile").style.display = "block";
                                    document.getElementById("EditFile").style.display = "block";
                                }
                                })
                                .catch(error => {
                                    window.console.log(error);
                                });
                            this.close()
                        
                    }
                     
                }
               else{
                  if(this.savedata.file == null){
                       this.showSnackbar("#b71c1c", "Please Select A File...");
                }
                 else {
                    let formData = new FormData();
                    formData.append("file", this.savedata.file);
                    formData.append("company_name", this.savedata.company_name);
                    formData.append("scheduling_id", this.scheduling_id);
                   // window.alert(JSON.stringify(formData))
                
                    document.getElementById("AddFile").style.display = "none";
                    this.dialog_upload=true
                    axios
                        .post("/TPOCompanyScheduling/saveAttachment", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                              
                                   this.dialog_upload=false;
                                this.showSnackbar("#4caf50", "File Addded Successfully...");
                                  document.getElementById("AddFile").style.display = "block";
                                    this.onLoad(); // show snackbar on success
                            } else {
                                 this.dialog_upload=false;
                                this.showSnackbar("#b71c1c", "File Already Present!!!");
                                    document.getElementById("AddFile").style.display = "block";
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });

                    
                }
               }
                
            },
   
    deleteFile(item) {
                const data = {
                    fileItem: item,
                };
                axios
                    .post("/TPOCompanyScheduling/deleteAttachment", data)
                    .then((res) => {
                        //console.log("inside axios  deleteFile")
                        if (res.data.msg == "200") {
                            
                            this.showSnackbar("#4caf50", "Deleted Successfully...");
                            this.onLoad()
                        } else {
                            //error
                            this.showSnackbar("#b71c1c", "Could Not DELTE FILE" );
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    })
                    this.close();
            },
   
    onLoad()
    {
    document.getElementById("cmp_list").style.display = "block";
    document.getElementById("cmp_name").style.display = "block";
    this.scheduling_id = this.$route.params.scheduling_id;
    this.selected_company = this.$route.params.companyname;
     if(this.scheduling_id==undefined)
   {
     this.scheduling_id=localStorage.getItem("scheduling_id")
   }
     if(this.selected_company==undefined)
   {
     this.selected_company=localStorage.getItem("companyname")
   }
    if (this.scheduling_id) {
      this.getofferinginfobyid();
      document.getElementById("cmp_list").style.display = "none";
      document.getElementById("cmp_name").style.display = "block";
    } else {
      this.get_company_list();
      document.getElementById("cmp_list").style.display = "block";
      document.getElementById("cmp_name").style.display = "none";
      }
    }
  },
  mounted() {
     this.onLoad();
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.c-title1 {
  background-image: linear-gradient(-90deg, #dbdbdb, #012944);
  color: #fff;
}
.link-icon {
  color: #fff;
}
.btn-title2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
</style>